import Swiper from "swiper/bundle";
import "swiper/css/bundle";
export default class Banner {
    constructor() {
        this._init();
    }

    _init() {
        this._slide();
    }

    _slide() {
        const $slideitemnum = [...document.querySelectorAll(".banner__item")];
        const $slide = document.querySelector(".banner");

        var loopval = "";
        var loopvalsp = "";

        if ($slideitemnum.length === 1) {
            loopval = false;
            loopvalsp = false;
            $slide.classList.add("-bannerone");
        } else if ($slideitemnum.length === 2) {
            loopval = false;
            loopvalsp = true;
        } else {
            loopval = true;
        }

        const option = {
            slidesPerView: 1,
            loop: loopvalsp,
            autoplay: { delay: 5000 },
            pagination: { el: ".swiper-pagination", clickable: true },
            observer: true,
            observeParents: true,
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    loop: loopval,
                },
            },
        };

        const swiper = new Swiper(".banner__slide", option);
    }
}
