import { Config } from "../common/config/config.es6";
import Storage from "../common/utils/storage.es6";

import Banner from "./func/banner.es6";
import Card from "./func/scrollfunc.es6";
import Intro from "./classes/intro.es6";

export default class Top {
    constructor() {
        window.CONFIG = Config;
        window.STORAGE = new Storage();

        this.banner = new Banner();
        this.card = new Card();
        this.intro = new Intro();
    }
}
