import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

export default class Card {
    constructor() {
        this.init();
    }

    init() {
        this.scrollKvParallax();
    }

    scrollKvParallax() {
        var isRunning = false;

        function handler() {
           // 呼び出されるまで何もしない
           if (!isRunning) {
               isRunning = true;

                // 描画する前のタイミングで呼び出してもらう
                window.requestAnimationFrame(function () {
                        // ここでなにか処理をする
                        var windowY = window.pageYOffset;

                        var deco1Y = windowY * 0.05;
                        const decobg1 = [
                            ...document.querySelectorAll(".js-para-deco-1"),
                        ];

                        decobg1.forEach((el) => {
                            el.style.transform = `translate(0,-${deco1Y}px)`;
                        });

                        var deco2Y = windowY * 0.03;
                        const decobg2 = [
                            ...document.querySelectorAll(".js-para-deco-2"),
                        ];

                        decobg2.forEach((el) => {
                            el.style.transform = `translate(0,-${deco2Y}px)`;
                        });

                        isRunning = false;
                });
           }
        }

        window.addEventListener("scroll", handler, { passive: true });
    }
}
