import { gsap } from "gsap";

export default class TopView {
    constructor() {
        this.tl = gsap.timeline();
    }

    setGsapTimelineObject() {
        // 初期化
        this.tl.set(".js-cardin-1", {
            rotationX: 360 * 0.8,
            rotationY: 360 * 0.5,
            y: -200,
            opacity: 0,
        });
        this.tl.set(".js-cardin-2", {
            rotationX: 360 * 0.6,
            rotationY: 360 * 0.3,
            y: -200,
            opacity: 0,
        });
        this.tl.set(".js-cardin-3", {
            rotationX: 360 * 0.5,
            rotationY: 360 * 0.7,
            y: -200,
            opacity: 0,
        });
        this.tl.set(".-block1 .mainvisual__date", {opacity: 0, y: -20});
        this.tl.set(".-block2 .mainvisual__date", { opacity: 0, y: -20 });
        this.tl.set(".-block3 .mainvisual__date", { opacity: 0, y: -20 });
        this.tl.set(".-block4 .mainvisual__date", { opacity: 0, y: -20 });
        this.tl.set(".-block5 .mainvisual__date", { opacity: 0, y: -20 });
        this.tl.set(".-block6 .mainvisual__date", { opacity: 0, y: -20 });
        this.tl.set(
            ".mainvisual__cards__item.-item1, .mainvisual__cards__item.-item4, .mainvisual__cards__item.-item7, .mainvisual__cards__item.-item10, .mainvisual__cards__item.-item13, .mainvisual__cards__item.-item16",
            { rotationX: 360 * 1.2, y: -200, opacity: 0 }
        );
        this.tl.set(
            ".mainvisual__cards__item.-item2, .mainvisual__cards__item.-item5, .mainvisual__cards__item.-item8, .mainvisual__cards__item.-item11, .mainvisual__cards__item.-item14, .mainvisual__cards__item.-item17",
            { rotationX: 360 * 1, y: -200, opacity: 0 }
        );
        this.tl.set(
            ".mainvisual__cards__item.-item3, .mainvisual__cards__item.-item6, .mainvisual__cards__item.-item9, .mainvisual__cards__item.-item12, .mainvisual__cards__item.-item15, .mainvisual__cards__item.-item18",
            { rotationX: 360 * 1, y: -200, opacity: 0 }
        );
    }

    cardAnimation() {
        this.tl.to(
            ".js-cardin-1",
            {
                duration: 2.0,
                opacity: 1,
                x: 0,
                y: 0,
                rotationX: 0,
                rotationY: 0,
                ease: "back.out(1.2)",
            },
            0
        );
         this.tl.to(
            ".js-cardin-2",
            {
                duration: 2.0,
                opacity: 1,
                x: 0,
                y: 0,
                rotationX: 0,
                rotationY: 0,
                ease: "back.out(1.2)",
            },
            0.2
         );
          this.tl.to(
            ".js-cardin-3",
            {
                duration: 2.0,
                opacity: 1,
                x: 0,
                y: 0,
                rotationX: 0,
                rotationY: 0,
                ease: "back.out(1.2)",
            },
            0.3
          );
       
        this.tl.to(
            ".mainvisual__cards__item.-item1, .mainvisual__cards__item.-item4, .mainvisual__cards__item.-item7, .mainvisual__cards__item.-item10, .mainvisual__cards__item.-item13, .mainvisual__cards__item.-item16",
            {
                duration: 1.5,
                opacity: 1,
                x: 0,
                y: 0,
                rotationX: 0,
                ease: "back.out(1.2)",
            },
            0
        );
        this.tl.to(
            ".mainvisual__cards__item.-item2, .mainvisual__cards__item.-item5, .mainvisual__cards__item.-item8, .mainvisual__cards__item.-item11, .mainvisual__cards__item.-item14, .mainvisual__cards__item.-item17",
            {
                duration: 1.7,
                opacity: 1,
                x: 0,
                y: 0,
                rotationX: 0,
                ease: "back.out(1.2)",
            },
            0.1
        );
        this.tl.to(
            ".mainvisual__cards__item.-item3, .mainvisual__cards__item.-item6, .mainvisual__cards__item.-item9, .mainvisual__cards__item.-item12, .mainvisual__cards__item.-item15, .mainvisual__cards__item.-item18",
            {
                duration: 1.8,
                opacity: 1,
                x: 0,
                y: 0,
                rotationX: 0,
                ease: "back.out(1.2)",
            },
            0
        );
        this.tl.to(".-block1 .mainvisual__date", {
            duration: 0.3,
            opacity: 1,
            y: 0,
            ease: "back.out(1.2)",
        },2.2);
        this.tl.to(".-block4 .mainvisual__date", {
            duration: 0.3,
            opacity: 1,
            y: 0,
            ease: "back.out(1.2)",
        },2.3);
        this.tl.to(".-block5 .mainvisual__date", {
            duration: 0.3,
            opacity: 1,
            y: 0,
            ease: "back.out(1.2)",
        },2.2);
        this.tl.to(".-block6 .mainvisual__date", {
            duration: 0.3,
            opacity: 1,
            y: 0,
            ease: "back.out(1.2)",
        },2.3);
        this.tl.to(".-block2 .mainvisual__date", {
            duration: 0.3,
            opacity: 1,
            y: 0,
            ease: "back.out(1.2)",
        },2.4);
        this.tl.to(".-block3 .mainvisual__date", {
            duration: 0.3,
            opacity: 1,
            y: 0,
            ease: "back.out(1.2)",
        },2.4);
    }
}