import Card from "../func/card.es6";

export default class Intro {
    constructor() {
        this.card = new Card();
        this._init();
    }

    _init() {
        this._evenHandler();
    }

    _evenHandler() {
       if (STORAGE.data.intro) {
            this._introStart();
            this._introEnd();
        } else {
            //イントロ非表示
            this._introSkip();
            this._introEnd();
        }
    }

    _introStart() {
        setTimeout(() => {
            this.card.setGsapTimelineObject();
            document.body.classList.add("-introin");
        }, 500);
        setTimeout(() => {
            this.card.cardAnimation();
        }, 2500);
        setTimeout(() => {
            document.body.classList.remove("-inintro");
            STORAGE.data.intro && STORAGE.set("intro", false);
        }, 3000);
    }

    _introEnd() {
        const $switchbtn = [
            ...document.querySelectorAll(".mainvisual__switch__btn"),
        ];

        $switchbtn.forEach((el) => {
            el.addEventListener("click", () => {
                if (document.body.classList.contains("-introend")) {
                    return;
                } else {
                    document.body.classList.add("-introend");
                }
            });
        });
    }

    _introSkip() {
        document.body.classList.add("-notroin");

        setTimeout(() => {
            this.card.setGsapTimelineObject();
            document.body.classList.add("-introin");
        }, 0);
        setTimeout(() => {
            this.card.cardAnimation();
        }, 100);
        setTimeout(() => {
            document.body.classList.remove("-inintro");
        }, 100);
    }
}
